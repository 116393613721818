@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

@layer components {
  .btn-primary {
    @apply  mt-2 flex justify-center items-center w-[120px] font-Yazi h-[40px] cursor-pointer border-2 border-white rounded-full hover:bg-white hover:text-[#1C1444] transition-all duration-300 ;
  }
}
